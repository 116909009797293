var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"n-observation-overview"},[(!_vm.loading)?_c('div',{staticClass:"n-observations"},[_c('div',{staticClass:"n-observations-header"},[_c('logo',{on:{"click":function($event){return _vm.$router.push('/')}}}),_c('Grid',{staticClass:"grid-icon"})],1),(_vm.welcomeImage)?_c('img',{attrs:{"src":_vm.welcomeImageSrc,"alt":_vm.welcomeImageAlt}}):_vm._e(),(!_vm.observations.length)?_c('div',{staticClass:"n-observations-empty"},[_vm._m(0)]):_vm._e(),_vm._l((Object.entries(
        _vm.sortSortedObservationsByLatestTally(_vm.sortedObservations)
      )),function([datasetId, datasetOptions]){return (_vm.observations.length)?_c('div',{key:datasetId,staticClass:"n-observation-overview-list-wrapper"},[(_vm.isLatestTally(datasetId))?_c('div',[_c('h1',{staticClass:"n-observation-overview-title"},[_vm._v(" Te gek, je hebt "),_c('span',{staticClass:"n-observation-overview-count"},[_vm._v(_vm._s(_vm.observationCountLabel(datasetOptions.name)))]),_vm._v(" geteld! ")]),_c('p',{staticClass:"n-observation-overview-subtitle"},[_vm._v(" Bekijk welke ")]),_c('ArrowDown'),_c('ul',{staticClass:"n-observation-overview-list"},_vm._l((Object.entries(datasetOptions.options)),function([speciesId, info]){return (_vm.latestTally ? _vm.latestTally.observationIds.includes(speciesId) : true)?_c('observation-list-item',{key:speciesId,attrs:{"speciesName":info.name,"count":_vm.calculateCount(speciesId, +info.count),"image":info.image}}):_vm._e()}),1),_c('a',{staticClass:"n-anchor",on:{"click":function($event){return _vm.openMap(datasetId, datasetOptions)}}},[_vm._v("Alle waarnemingen bekijken ↗")])],1):_vm._e(),(_vm.latestTally ? _vm.latestTally.observationIds.length !== _vm.observations.length : true)?_c('div',[_c('h1',{staticClass:"n-observation-overview-title default-title"},[_vm._v(" Je "+_vm._s(_vm.isLatestTally(datasetId) ? 'andere' : '')+" waarnemingen ")]),_c('ul',{staticClass:"n-observation-overview-list"},_vm._l((Object.entries(datasetOptions.options)),function([speciesId, info]){return (!!_vm.calculateCount(speciesId, +info.count, true))?_c('observation-list-item',{key:speciesId,attrs:{"speciesName":info.name,"count":_vm.calculateCount(speciesId, +info.count, true),"image":info.image}}):_vm._e()}),1),(
            !!_vm.calculateCount(
              Object.keys(datasetOptions.options)[0],
              Object.entries(datasetOptions.options)[0][1].count,
              true
            )
          )?_c('a',{staticClass:"n-anchor",on:{"click":function($event){return _vm.openMap(datasetId, datasetOptions)}}},[_vm._v("Alle waarnemingen bekijken ↗")]):_vm._e()]):_vm._e()]):_vm._e()}),(_vm.latestDatasetName)?_c('progress-button',{attrs:{"className":"n-confirm-btn"},on:{"click":_vm.navigateToTally},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ik wil meer "+_vm._s(_vm.latestDatasetName)+" tellen ")]},proxy:true}],null,false,80879309)}):_vm._e(),_c('progress-button',{attrs:{"className":"n-confirm-btn"},on:{"click":_vm.navigateToOnboarding},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ik wil iets "+_vm._s(!_vm.observations.length ? '' : 'anders')+" tellen ")]},proxy:true}],null,false,2123848127)})],2):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" Je hebt nog geen soorten geturfd."),_c('br'),_vm._v(" Klik op \"Ik wil iets tellen\" om te beginnen!. ")])
}]

export { render, staticRenderFns }