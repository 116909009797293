
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Mutation, Getter } from 'vuex-class';
import _ from 'lodash';

import Eye from '@/components/icons/Eye.vue';
import Minus from '@/components/icons/Minus.vue';
import Plus from '@/components/icons/Plus.vue';
import PlusRed from '@/components/icons/PlusRed.vue';
import { IToolOption } from '@/shared/models/dataset';
import { IFirestoreObservation } from '@/shared/models/firestore';
import { EventBus, EventTypes } from '@/shared/utils/EventBus';
import geolocation from '@/shared/utils/geolocation';

@Component({
  components: {
    Eye,
    Minus,
    Plus,
    PlusRed,
  },
})
export default class ObservationGridTile extends Vue {
  @Mutation public incrementObservation!: (observation: IFirestoreObservation) => void;
  @Mutation public decrementObservation!: (speciesId: string) => void;
  @Getter public userId!: string;

  @Prop() public species!: IToolOption;
  @Prop() public datasetId!: string;
  @Prop() public detailsIsOpen!: boolean;

  public amount: number = 0;

  created() {
    EventBus.$on(EventTypes.incrementSpecies, () => {
      if (this.detailsIsOpen) this.increment();
    });
    EventBus.$on(EventTypes.decrementSpecies, () => {
      if (this.detailsIsOpen) this.decrement();
    });
  }

  public get geolocation() {
    return geolocation;
  }

  public get speciesName() {
    return _.capitalize(this.species.speciesName);
  }

  public increment() {
    this.amount++;
    this.$emit('update-amount', this.amount, this.species.speciesId);

    const observation = this.getObservation();

    observation.lat = this.geolocation.latitude;
    observation.lon = this.geolocation.longitude;

    this.incrementObservation(observation);
  }

  public decrement() {
    if (this.amount <= 0) {
      return;
    }

    this.amount--;
    this.$emit('update-amount', this.amount, this.species.speciesId);
    this.decrementObservation(this.species.speciesId);
  }

  private getObservation(): IFirestoreObservation {
    const { speciesName, speciesId } = this.species;
    return {
      id: 'temp_id',
      datasetId: this.datasetId,
      speciesId,
      speciesName,
      source: 'natuurlab',
      lat: 0,
      lon: 0,
      timestamp: new Date().toISOString(),
      number: 1,
      userId: this.userId,
      validationStatus: 'O',
    };
  }
}
