
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ProgressButton from '@/components/ProgressButton.vue';
import { IFirestoreObservation } from '@/shared/models/firestore';
import { IGenericTurfappContent } from '@/types/contentful';

@Component({
  components: {
    ProgressButton,
  },
})
export default class Home extends Vue {
  @Getter public genericContent!: IGenericTurfappContent;
  @Getter public observations!: IFirestoreObservation[];

  public navigateToOnboarding() {
    this.$router.push('/onboarding');
  }

  public get welcomeImage() {
    return this.genericContent ? this.genericContent.image.fields : null;
  }
}
