import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
Vue.config.productionTip = false;

// tslint:disable-next-line
require('../node_modules/mapbox-gl/dist/mapbox-gl.css');

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
