
import { Component, Vue } from 'vue-property-decorator';
import ProgressButton from '@/components/ProgressButton.vue';

@Component({
  components: {
    ProgressButton,
  },
})
export default class OnboardingSteps extends Vue {
  public amountOfSteps: number = 3;
  public activeStep: number = 0;

  public navigateToLocation() {
    this.$router.push('/');
  }
}
