import axios, { AxiosResponse } from 'axios';

import { IObservations } from '@/shared/models/dataset';
import { IFirestoreObservation } from '@/shared/models/firestore';
import JsonUtils from '@/shared/utils/JsonUtils';

const FUNCTIONS_ENDPOINT = process.env.VUE_APP_FUNCTIONS_ENDPOINT;

export default class Api {
  public static async addObservations(
    observations: IFirestoreObservation[]
  ): Promise<AxiosResponse<any>> {
    const observationFeatures = observations.map(obs => {
      // tslint:disable:variable-name
      const {
        speciesId,
        speciesName,
        datasetId,
        source,
        timestamp,
        lat,
        lon,
        userId,
        id,
        validationStatus,
        number,
      } = obs;

      return JsonUtils.formatFeature([lon, lat], {
        id,
        userId,
        datasetId,
        speciesId,
        speciesName,
        source,
        timestamp,
        number,
        validationStatus,
      });
    });

    const geojson: IObservations = JsonUtils.formatFeatures(observationFeatures);

    return await axios.post(`${FUNCTIONS_ENDPOINT}/addObservations`, geojson);
  }
}
