export default class JsonUtils {
  public static formatFeature(coordinates: number[], properties: any) {
    return {
      properties,
      geometry: {
        coordinates,
        type: 'Point',
      },
      type: 'Feature',
    };
  }

  public static formatFeatures(features: any[]) {
    return {
      features,
      type: 'FeatureCollection',
    };
  }

  public static isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
