
import { Component, Prop, Vue } from 'vue-property-decorator';
import capitalize from 'lodash/capitalize';

import Close from '@/components/icons/Close.vue';
import Minus from '@/components/icons/Minus.vue';
import Plus from '@/components/icons/Plus.vue';
import PlusRed from '@/components/icons/PlusRed.vue';
import { IToolOption } from '@/shared/models/dataset';
import { EventBus, EventTypes } from '@/shared/utils/EventBus';

@Component({
  components: {
    Close,
    Minus,
    Plus,
    PlusRed,
  },
})
export default class ObservationDetails extends Vue {
  @Prop() public species!: IToolOption;
  @Prop() public amount!: number;

  public capitalizeStr(str: string): string {
    return capitalize(str);
  }

  public decrement() {
    EventBus.$emit(EventTypes.decrementSpecies);
  }

  public increment() {
    EventBus.$emit(EventTypes.incrementSpecies);
  }
}
