
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';

import { IFirestoreObservation } from '@/shared/models/firestore';

import Logo from '@/components/Logo.vue';
import ObservationListItem from '@/components/ObservationListItem.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import ArrowDown from '@/components/icons/ArrowDown.vue';
import Grid from '@/components/icons/Grid.vue';
import { IObservationsPerDataset } from '@/shared/models/dataset';
import { IGenericTurfappContent } from '@/types/contentful';
import qs from 'qs';

@Component({
  components: {
    Logo,
    ObservationListItem,
    ProgressButton,
    ArrowDown,
    Grid,
  },
})
export default class ObservationOverview extends Vue {
  @Getter public observations!: IFirestoreObservation[];
  @Getter public sortedObservations!: IObservationsPerDataset;
  @Getter public latestTally!: { datasetId: string; observationIds: string[] } | null;
  @Getter public loading!: boolean;
  @Getter public genericContent!: IGenericTurfappContent;
  @Mutation public clearLatestTally!: () => void;

  public get welcomeImage() {
    return this.genericContent ? this.genericContent.image.fields : null;
  }

  public get welcomeImageSrc() {
    return !!this.welcomeImage ? this.welcomeImage.file.url : '';
  }

  public get welcomeImageAlt() {
    return !!this.welcomeImage
      ? this.welcomeImage.description
      : 'Een Naturalis wetenschapper moedigt je aan!';
  }

  public get latestDatasetName() {
    return this.latestTally ? this.sortedObservations[this.latestTally.datasetId].name : '';
  }

  public navigateToOnboarding() {
    this.$router.push('/onboarding');
  }

  public navigateToTally() {
    if (!this.latestTally) return;

    this.$router.push(`/turven/${this.latestTally.datasetId}`);
    this.clearLatestTally();
  }

  public openMap(datasetId: string, datasetInfo: IObservationsPerDataset | any) {
    const obj = {
      id: 0,
      dataset: { datasetId, name: datasetInfo.name, year: 'all' },
      view: {
        label: datasetInfo.speciesName,
        component: 'Map',
        icon: 'map',
        options: undefined,
      },
    };
    const asQs = qs.stringify(obj, { arrayFormat: 'repeat', allowDots: true });
    const url = process.env.VUE_APP_NATUURLAB_URL + '/dataview/preview' + `?${asQs}`;
    window.open(url, '_blank');
  }

  public observationCountLabel(name: string) {
    const count = this.latestTally ? this.latestTally.observationIds.length : 0;

    return `${count} ${name}`;
  }

  public sortSortedObservationsByLatestTally(observations: IObservationsPerDataset) {
    if (!this.latestTally) return observations;

    return {
      [this.latestTally.datasetId]: observations[this.latestTally.datasetId],
      ...observations,
    };
  }

  public isLatestTally(datasetId: string) {
    return !!this.latestTally ? this.latestTally.datasetId === datasetId : false;
  }

  public calculateCount(speciesId: string, count: number | any, diff: boolean = false) {
    if (!!this.latestTally && this.latestTally.observationIds.includes(speciesId)) {
      const talliedCount = this.latestTally.observationIds.filter(id => id === speciesId).length;
      return diff ? count - talliedCount : talliedCount;
    }
    return count;
  }
}
