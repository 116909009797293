
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProgressButton extends Vue {
  @Prop() public className?: string;
  @Prop() public disabled?: boolean;
  @Prop() public nextStep?: number;
  @Prop() public isSecondary?: boolean;

  public get classNames(): (string | undefined)[] {
    return ['n-btn', this.className];
  }

  public get bgColor(): string {
    return this.isSecondary ? '#212121' : '#f9423a';
  }

  public getLabel() {
    return `Door naar stap ${this.nextStep}`;
  }
}
