import Vue from 'vue';

const geolocation = Vue.observable({
  latitude: 0,
  longitude: 0,
  watching: false,
});

export function updatePosition(latitude: number, longitude: number, watching: boolean) {
  geolocation.latitude = latitude;
  geolocation.longitude = longitude;
  geolocation.watching = watching;
}

export default geolocation;
