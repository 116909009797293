
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import capitalize from 'lodash/capitalize';

import Logo from '@/components/Logo.vue';
import LogoIcon from '@/components/icons/LogoIcon.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import { IDataset } from '@/shared/models/dataset';

@Component({
  components: {
    Logo,
    LogoIcon,
    ProgressButton,
  },
})
export default class DatasetOverview extends Vue {
  @Getter public datasets!: IDataset[];
  @Getter public datasetsError!: Error | null;
  @Getter public loading!: boolean;

  public selectedDatasetId: string | null = null;

  public get datasetsHighlightedFirst(): IDataset[] {
    return this.datasets.slice().sort((a, _b) => (a.highlighted ? -1 : 1));
  }

  public capitalizeStr(str?: string): string {
    return capitalize(str);
  }

  public selectDataset(id: string) {
    this.selectedDatasetId = this.selectedDatasetId !== id ? id : null;
  }

  public navigateToTallyView() {
    this.$router.push(`/turven/${this.selectedDatasetId}`);
  }
}
