import { Vue } from 'vue-property-decorator';

const EventBus = new Vue();

enum EventTypes {
  modalOpen = 'MODAL_OPEN',
  modalClosed = 'MODAL_CLOSED',
  menuOpen = 'MENU_OPEN',
  menuClosed = 'MENU_CLOSED',
  incrementSpecies = 'INCREMENT_SPECIES',
  decrementSpecies = 'DECREMENT_SPECIES',
}

export { EventBus, EventTypes };
