
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Loader from '@/components/Loader.vue';
import Logo from '@/components/Logo.vue';
import OnboardingSteps from '@/components/OnboardingSteps.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import TrackLocation, { TrackMode } from '@/components/TrackLocation.vue';
import { IFirestoreObservation } from '@/shared/models/firestore';
import geolocation, { updatePosition } from '@/shared/utils/geolocation';

@Component({
  components: {
    Loader,
    Logo,
    OnboardingSteps,
    ProgressButton,
    TrackLocation,
  },
})
export default class Onboarding extends Vue {
  @Getter public observations!: IFirestoreObservation[];

  public amountOfSteps: number = 3;
  public activeStep: number = 0;
  public canContinue: boolean = true;
  public trackMode: TrackMode | null = 'auto';
  public autoTrackingSet: boolean = false;
  public errorMsg: string | null = null;
  public fetchingLocation: boolean = false;

  public mounted() {
    this.activeStep = !this.observations.length ? 0 : 1;
  }

  public handleTrackMode(mode: TrackMode) {
    this.canContinue = !!mode;
    this.trackMode = mode;
  }

  public async goToStep(step: number) {
    this.activeStep = step;

    if (step === 2) {
      if (this.trackMode === 'auto') {
        this.checkPermissions();
      } else {
        this.navigateToTally();
      }
    }
  }

  public async checkPermissions() {
    const result = await (navigator as any).permissions.query({ name: 'geolocation' });

    if (result.state === 'granted') {
      try {
        await this.setGeolocation();
      } catch (error) {
        this.setGeolocationFailedError();
      }
    }
    if (result.state === 'prompt') {
      this.setGeolocation();
      this.setNoPermissionError();
    }
    if (result.state === 'denied') {
      this.setNoPermissionError();
    }
  }

  private setGeolocation() {
    this.fetchingLocation = true;
    if (geolocation.watching) this.navigateToTally();

    const timeout = setTimeout(() => this.setGeolocationFailedError(), 10000);

    return new Promise<void>((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.watchPosition(position => {
          const { latitude, longitude } = position.coords;

          updatePosition(latitude, longitude, true);

          this.autoTrackingSet = true;
          this.fetchingLocation = false;

          clearTimeout(timeout);
          resolve();
        });
      } else {
        reject();
      }
    });
  }

  public closeError() {
    this.errorMsg = null;
  }

  private setGeolocationFailedError() {
    this.activeStep--;
    this.fetchingLocation = false;
    this.errorMsg = 'Er is iets misgegaan met het ophalen van je locatie. Probeer het nog eens.';
  }

  private setNoPermissionError() {
    this.errorMsg =
      'Om optimaal gebruik te maken van de turf tool is het aangeraden om toestemming te geven tot je locatie.';
  }

  private navigateToTally() {
    this.$router.push('/turven');
  }

  @Watch('autoTrackingSet')
  private(value: boolean) {
    if (value && !this.fetchingLocation) {
      this.navigateToTally();
    }
  }
}
