var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.opened)?_c('div',{staticClass:"n-observation-confirm"},[_c('div',{staticClass:"n-observation-confirm-content"},[_c('div',{staticClass:"n-observation-confirm-text"},[_c('div',{staticClass:"n-observation-confirm-header"},[_c('logo'),_c('div',{staticClass:"n-observation-confirm-progress"},[_c('Map',{staticClass:"map-icon"})],1)],1),_c('h1',{staticClass:"n-observation-confirm-title"},[_vm._v("Waar heb je de "+_vm._s(_vm.datasetName)+" gezien?")]),_c('p',{staticClass:"n-observation-confirm-subtitle"},[_vm._v(" Gebruik de 'Mijn locatie'-knop om je huidige locatie te bepalen, of bepaal je locatie door een punt op de kaart te selecteren. ")])]),(_vm.loadMap)?_c('div',{staticClass:"n-observation-confirm-map"},[_c('mapbox',{staticClass:"mapbox-confirm",attrs:{"access-token":_vm.mapAccessToken,"map-options":{
          style: 'mapbox://styles/mapbox/streets-v10',
          center: [5.297099462951223, 52.348261484763526],
          zoom: 7,
          container: '1',
          attributionControl: false,
        }},on:{"map-init":_vm.onMapInit,"map-load":_vm.onMapLoad,"map-click":_vm.onMapClick}}),_c('div',{ref:"marker",staticClass:"n-marker",class:{ hidden: _vm.lat === 0 && _vm.lon === 0 }}),_c('loader',{attrs:{"loading":!_vm.mapLoaded}})],1):_vm._e(),(_vm.confirmDisabled && !_vm.fetchingLocation)?_c('progress-button',{attrs:{"className":"n-confirm-btn"},on:{"click":() => _vm.handleGeolocation()},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"btn-label"},[_vm._v("Mijn locatie"),_c('Location')],1)]},proxy:true}],null,false,77295677)}):_vm._e(),(_vm.fetchingLocation)?_c('div',{staticClass:"n-observation-confirm-loader"},[_c('loader',{attrs:{"inline":true,"loading":true}})],1):_vm._e(),(!_vm.confirmDisabled && !_vm.fetchingLocation)?_c('div',{staticClass:"n-confirm-btn-wrapper"},[_c('button',{staticClass:"n-location-btn",on:{"click":() => _vm.handleGeolocation()}},[_c('Location',{staticClass:"location-icon"})],1),_c('progress-button',{attrs:{"className":"n-confirm-btn secondary-btn"},on:{"click":() => _vm.handleConfirm()},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"btn-label"},[_vm._v("Bevestig")])]},proxy:true}],null,false,2720318440)})],1):_vm._e(),_c('transition',{attrs:{"name":"slide-up"}},[(_vm.errorMsg)?_c('div',{staticClass:"n-error-message"},[_c('button',{staticClass:"n-close-btn",on:{"click":_vm.closeError}},[_vm._v("x")]),_c('p',[_vm._v(_vm._s(_vm.errorMsg))])]):_vm._e()]),_c('loader',{attrs:{"loading":_vm.loading,"is-fullscreen":true}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }