
import { Component, Vue } from 'vue-property-decorator';
import Crosshair from '@/components/icons/Crosshair.vue';
import Info from '@/components/icons/Info.vue';
import Map from '@/components/icons/Map.vue';

export type TrackMode = 'auto' | 'manual';

@Component({
  components: {
    Crosshair,
    Info,
    Map,
  },
})
export default class TrackLocation extends Vue {
  amountOfSteps: number = 3;
  activeStep: number = 0;
  selectedTrackMode: TrackMode | null = 'auto';

  setTrackMode(mode: TrackMode): void {
    this.selectedTrackMode = mode !== this.selectedTrackMode ? mode : null;
    this.$emit('set-track-mode', this.selectedTrackMode);
  }
}
