
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';

import { IDataset, IToolOption } from '@/shared/models/dataset';
import { IFirestoreObservation } from '@/shared/models/firestore';

import Logo from '@/components/Logo.vue';
import ObservationConfirm from '@/components/ObservationConfirm.vue';
import ObservationDetails from '@/components/ObservationDetails.vue';
import ObservationTips from '@/components/ObservationTips.vue';
import ObservationGridTile from '@/components/ObservationGridTile.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import TallyNotification from '@/components/TallyNotification.vue';
import Grid from '@/components/icons/Grid.vue';
import Info from '@/components/icons/Info.vue';
import Map from '@/components/icons/Map.vue';
import geolocation from '@/shared/utils/geolocation';

@Component({
  components: {
    Logo,
    ObservationConfirm,
    ObservationDetails,
    ObservationTips,
    ObservationGridTile,
    ProgressButton,
    TallyNotification,
    Grid,
    Info,
    Map,
  },
})
export default class AddObservation extends Vue {
  @Action public addObservations!: (coords?: { lat: number; lon: number }) => void;
  @Mutation public clearPendingObservations!: () => void;
  @Mutation public setLatestTally!: (latestTally: {
    datasetId: string;
    observationIds: string[];
  }) => void;
  @Getter public datasets!: IDataset[];
  @Getter public observations!: IFirestoreObservation[];
  @Getter public pendingObservations!: IFirestoreObservation[];

  public errorMsg: string | null = null;
  public geolocationModalOpened: boolean = false;
  public loadModalMap: boolean = false;
  public notificationIsVisible: boolean = false;
  public firstTally: boolean = false;
  public selectedSpecies: IToolOption | null = null;
  public selectedSpeciesAmount: number = 0;
  public tipsIsOpen: boolean = false;

  public openDetails(species: IToolOption, amount: number) {
    this.selectedSpecies = species;
    this.selectedSpeciesAmount = amount;
  }

  public toggleTips() {
    this.tipsIsOpen = !this.tipsIsOpen;
  }

  public handleUpdateAmount(amount: number, id: string) {
    if (this.selectedSpecies && this.selectedSpecies.speciesId === id) {
      this.selectedSpeciesAmount = amount;
    }
  }

  public async handleConfirm() {
    try {
      this.navigateToObservations();

      await this.addObservations();
    } catch (error) {
      console.error(error);
    }
  }

  public async handleNext() {
    if (this.nextDisabled) {
      this.errorMsg = 'Turf minimaal één soort om verder te gaan';
      return;
    }

    if (this.geolocation.watching) {
      await this.handleConfirm();
    } else {
      this.geolocationModalOpened = true;
    }
  }

  public closeModal() {
    this.geolocationModalOpened = false;
  }

  public closeDetails() {
    this.selectedSpecies = null;
  }

  public afterModalOpen() {
    this.loadModalMap = true;
  }

  public get hasTipsContent() {
    return this.dataset && !!(this.dataset.titleTips || this.dataset.descriptionTips);
  }

  public get hasToolOptions() {
    return this.dataset && this.dataset.toolOptions && this.dataset.toolOptions.length > 0;
  }

  public get showTallyNotification() {
    return !this.observations.length && this.notificationIsVisible && this.firstTally;
  }

  public get showProgressButton() {
    return (
      !!this.pendingObservations.length &&
      !this.notificationIsVisible &&
      !this.geolocationModalOpened
    );
  }

  public get nextDisabled(): boolean {
    return this.pendingObservations.length === 0;
  }

  public get geolocation() {
    return geolocation;
  }

  private beforeMount() {
    this.clearPendingObservations();
  }

  public closeError() {
    this.errorMsg = null;
  }

  private navigateToObservations() {
    if (this.dataset) {
      this.setLatestTally({
        datasetId: this.dataset.id,
        observationIds: this.pendingObservations.map(obs => obs.speciesId),
      });
    }
    this.$router.push('/waarnemingen');
  }

  get dataset() {
    return this.datasets.find(ds => ds.id === this.$route.params.datasetId);
  }

  @Watch('pendingObservations')
  private(value: IFirestoreObservation[]) {
    if (value.length === 1 && !this.firstTally && !this.observations.length) {
      this.firstTally = true;
      this.notificationIsVisible = true;
    }

    setTimeout(() => {
      this.notificationIsVisible = false;
    }, 5000);
  }
}
