
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Info from '@/components/icons/Info.vue';
import { IGenericTurfappContent } from '@/types/contentful';

@Component({
  components: {
    Info,
  },
})
export default class ObservationTips extends Vue {
  @Getter public genericContent!: IGenericTurfappContent;
  @Prop() public tipsIsOpen!: boolean;
  @Prop() public title?: string;
  @Prop() public description?: string;

  public get imageSrc() {
    return !!this.genericContent ? this.genericContent.image.fields.file.url : '';
  }

  public get imageAlt() {
    return !!(this.genericContent && this.genericContent.image.fields.description)
      ? this.genericContent.image.fields.description
      : 'Een enthousiaste Naturalis wetenschapper';
  }
}
