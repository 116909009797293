import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import { IDataset, IToolOption } from '@/shared/models/dataset';
import { IFirestoreObservation } from '@/shared/models/firestore';

export type Email = string;

export default class Firebase {
  private db: firebase.firestore.Firestore;
  private auth: firebase.auth.Auth;
  private datasets: firebase.firestore.CollectionReference;
  private users: firebase.firestore.CollectionReference;

  constructor() {
    const config = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }

    this.db = firebase.firestore();
    this.auth = firebase.auth();
    this.datasets = this.db.collection('datasets');
    this.users = this.db.collection('users');
  }

  public getAuth(): firebase.auth.Auth {
    return this.auth;
  }

  public async getDatasets(): Promise<IDataset[]> {
    const datasetsSnap = await this.datasets.get();
    const datasets = datasetsSnap.docs.map(doc => doc.data() as IDataset);

    const withToolOptions = await Promise.all(
      datasets.map(async dataset => {
        const toolOptionsSnap = await this.datasets
          .doc(dataset.id)
          .collection('toolOptions')
          .orderBy('order')
          .get();
        const toolOptions = toolOptionsSnap.docs.map(
          doc =>
            ({
              ...doc.data(),
              speciesId: doc.id,
            } as IToolOption)
        );
        return {
          ...dataset,
          toolOptions,
        };
      })
    );

    return withToolOptions;
  }

  public async getObservations(userId: string): Promise<IFirestoreObservation[]> {
    const ref = this.users.doc(userId).collection('observations');
    const snapshot = await ref.get();

    const observations: IFirestoreObservation[] = snapshot.docs.map(doc => {
      const data = doc.data();
      return data as IFirestoreObservation;
    });

    return observations;
  }

  public async addObservations(userId: string, obs: any): Promise<IFirestoreObservation[]> {
    const batch = this.db.batch();
    const observations: IFirestoreObservation[] = obs.map((o: any) => {
      const ref = this.users
        .doc(userId)
        .collection('observations')
        .doc();
      const observation = { ...o, id: ref.id };

      batch.set(ref, observation);

      return observation;
    });

    await batch.commit();

    return observations;
  }
}
