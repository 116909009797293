
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { AttributionControl, Marker } from 'mapbox-gl';
import Mapbox from 'mapbox-gl-vue';

import Loader from '@/components/Loader.vue';
import Logo from '@/components/Logo.vue';
import ProgressButton from '@/components/ProgressButton.vue';
import Location from '@/components/icons/Location.vue';
import Map from '@/components/icons/Map.vue';
import { IFirestoreObservation } from '@/shared/models/firestore';

@Component({
  components: {
    Loader,
    Logo,
    ProgressButton,
    Location,
    Map,
    Mapbox,
  },
})
export default class ObservationConfirm extends Vue {
  @Action public addObservations!: (coords?: { lat: number; lon: number }) => void;
  @Getter public loading!: boolean;
  @Getter public pendingObservations!: IFirestoreObservation[];
  @Mutation public setLatestTally!: (latestTally: {
    datasetId: string;
    observationIds: string[];
  }) => void;

  @Prop() public loadMap!: boolean;
  @Prop() public opened!: boolean;
  @Prop() public datasetId!: string;
  @Prop() public datasetName!: string;

  public mapbox: any = null;
  public lat: number = 0;
  public lon: number = 0;
  public errorMsg: string | null = null;
  public fetchingLocation: boolean = false;
  public mapLoaded: boolean = false;

  public onMapInit(mapbox: any) {
    this.mapbox = mapbox;

    const customAttr = new AttributionControl({ compact: true });
    mapbox.addControl(customAttr);

    const viewportMetaEl = document.querySelector('meta[name="viewport"]');

    if (!!viewportMetaEl) {
      viewportMetaEl.setAttribute(
        'content',
        'width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
      );
    }
  }

  public onMapLoad(mapbox: any) {
    this.mapLoaded = true;
  }

  public onMapClick(mapbox: any, e: any) {
    const { lat, lng } = e.lngLat;
    this.lat = lat;
    this.lon = lng;

    this.setMarker();
  }

  public get mapAccessToken(): string {
    return process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
  }

  public async handleGeolocation() {
    this.checkPermissions();
  }

  public async checkPermissions() {
    const result = await (navigator as any).permissions.query({ name: 'geolocation' });

    if (result.state === 'granted') {
      try {
        await this.setGeolocation();
      } catch (error) {
        this.setGeolocationFailedError();
      }
    }
    if (result.state === 'prompt') {
      this.setGeolocation();
      this.setNoPermissionError();
    }
    if (result.state === 'denied') {
      this.setNoPermissionError();
    }
  }

  public get confirmDisabled(): boolean {
    return this.lat === 0 || this.lon === 0;
  }

  public async handleConfirm() {
    if (this.confirmDisabled) {
      this.setEmptyError();
      return;
    }

    try {
      this.navigateToObservations();

      await this.addObservations({ lat: this.lat, lon: this.lon });
    } catch (error) {
      this.setGeolocationFailedError();
    }
  }

  public closeError() {
    this.errorMsg = null;
  }

  private beforeMount() {
    window.addEventListener('popstate', this.handleBack);
  }

  private destroyed() {
    const viewportMetaEl = document.querySelector('meta[name="viewport"]');

    if (!!viewportMetaEl) {
      viewportMetaEl.setAttribute('content', 'width=device-width,initial-scale=1.0');
      window.removeEventListener('popstate', this.handleBack);
    }
  }

  private handleBack(e: Event) {
    e.preventDefault();
    this.$emit('close');
  }

  private setMarker() {
    const { lat, lon } = this;

    new Marker(this.$refs.marker as HTMLElement, {
      offset: {
        x: 0,
        y: -25,
      } as any,
    })
      .setLngLat({ lat, lon })
      .addTo(this.mapbox);
  }

  private setNoPermissionError() {
    this.errorMsg = `Om optimaal gebruik te maken van de turftool is het aangeraden
      om toestemming te geven tot je locatie.`;
  }

  private setGeolocationFailedError() {
    this.fetchingLocation = false;
    this.errorMsg = 'Er is iets misgegaan met het ophalen van je locatie. Probeer het nog eens.';
  }

  private setEmptyError() {
    this.errorMsg = 'Je hebt geen locatie opgegeven. Geef deze op om te bevestigen.';
  }

  private navigateToObservations() {
    this.setLatestTally({
      datasetId: this.datasetId,
      observationIds: this.pendingObservations.map(obs => obs.speciesId),
    });
    this.$router.push('/waarnemingen');
  }

  private flyToLocation(lat: number, lon: number) {
    this.mapbox.flyTo({
      center: [lat, lon],
    });
  }

  private setGeolocation() {
    const timeout = setTimeout(() => this.setGeolocationFailedError(), 10000);
    this.fetchingLocation = true;

    return new Promise<void>((resolve, reject) => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude, longitude } = position.coords;

          this.lat = latitude;
          this.lon = longitude;
          this.fetchingLocation = false;

          this.flyToLocation(longitude, latitude);
          this.setMarker();

          clearTimeout(timeout);
          resolve();
        });
      } else {
        reject();
      }
    });
  }
}
