
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { IGenericTurfappContent } from '@/types/contentful';

@Component({})
export default class TallyNotification extends Vue {
  @Getter public genericContent!: IGenericTurfappContent;

  public get tallyImage() {
    return this.genericContent ? this.genericContent.image.fields : null;
  }
}
