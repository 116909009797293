
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import Loader from '@/components/Loader.vue';

@Component({
  components: {
    Loader,
  },
})
export default class App extends Vue {
  @Getter public observationsError!: Error | null;
  @Getter public datasetsError!: Error | null;
  @Getter public loading!: boolean;

  @Action public logIn!: () => void;
  @Action public fetchObservations!: () => void;
  @Action public fetchDatasets!: () => void;
  @Action public fetchGenericContent!: () => void;

  public async mounted() {
    await this.logIn();
    await Promise.all([this.fetchObservations(), this.fetchDatasets(), this.fetchGenericContent()]);
  }

  public navigateHome() {
    this.$router.push('/');
  }

  public get hasErrors() {
    return this.observationsError || this.datasetsError;
  }
}
