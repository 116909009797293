import Vue from 'vue';
import Router from 'vue-router';
import AddObservation from './views/AddObservation.vue';
import Home from './views/Home.vue';
import Observations from './views/Observations.vue';
import Onboarding from './views/Onboarding.vue';
import SelectDataset from './views/SelectDataset.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: Onboarding,
    },
    {
      path: '/waarnemingen',
      name: 'waarnemingen overzicht',
      component: Observations,
    },
    {
      path: '/turven',
      name: 'observatie selecteren',
      component: SelectDataset,
    },
    {
      path: '/turven/:datasetId',
      name: 'observatie toevoegen',
      component: AddObservation,
    },
  ],
});
