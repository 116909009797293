import { createClient } from 'contentful';
import { ContentTypes, IGenericTurfappContent } from '@/types/contentful';

const client = createClient({
  space: process.env.VUE_APP_CONTENTFUL_SPACE,
  accessToken: process.env.VUE_APP_CONTENTFUL_API_KEY,
  environment: process.env.VUE_APP_CONTENTFUL_ENVIRONMENT,
});

export default class ContentApi {
  public static getClient() {
    return client;
  }

  public static async fetchGenericTurfappContent(): Promise<IGenericTurfappContent | null> {
    const { items } = await client.getEntries<IGenericTurfappContent>({
      content_type: ContentTypes.GenericTurfappContent,
    });

    if (!items || !items.length) return null;

    return items[0].fields;
  }
}
