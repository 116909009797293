import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import Api from '@/api/Api';
import ContentApi from '@/api/ContentApi';
import { firebaseService } from '@/services';
import { IDataset, IObservationsPerDataset } from '@/shared/models/dataset';
import { IFirestoreObservation } from '@/shared/models/firestore';
import { IGenericTurfappContent } from '@/types/contentful';
import { fromPairs } from 'lodash';

Vue.use(Vuex);

interface IRootState {
  datasets: IDataset[];
  datasetsError: Error | null;
  userId: string | null;
  observations: IFirestoreObservation[];
  observationsError: Error | null;
  pendingObservations: IFirestoreObservation[];
  loading: boolean;
  genericContent: IGenericTurfappContent | null;
  latestTally: { datasetId: string; observationIds: string[] } | null;
}

const store: StoreOptions<IRootState> = {
  state: {
    datasets: [],
    datasetsError: null,
    userId: null,
    observations: [],
    observationsError: null,
    pendingObservations: [],
    loading: true,
    genericContent: null,
    latestTally: null,
  },
  getters: {
    latestTally(state) {
      return state.latestTally;
    },
    datasets(state) {
      return state.datasets;
    },
    datasetsError(state) {
      return state.datasetsError;
    },
    userId(state) {
      return state.userId;
    },
    observations(state) {
      return state.observations;
    },
    genericContent(state) {
      return state.genericContent;
    },
    observationsError(state) {
      return state.observationsError;
    },
    pendingObservations(state) {
      return state.pendingObservations;
    },
    loading(state) {
      return state.loading;
    },
    sortedObservations(state): IObservationsPerDataset {
      const observationsPerDataset = state.observations.reduce(
        (namedObservations, obs) => {
          const dataset: any = state.datasets.find(d => d.id === obs.datasetId);
          const datasetName: any = dataset ? dataset.name : null;
          if (!datasetName) {
            return namedObservations;
          }
          const observationsForDataset = namedObservations[dataset.id];
          return {
            ...namedObservations,
            [dataset.id]: observationsForDataset
              ? {
                  ...observationsForDataset,
                  observations: observationsForDataset.observations.concat(obs),
                }
              : { name: datasetName, observations: [obs] },
          };
        },
        {} as { [setName: string]: { name: string; observations: IFirestoreObservation[] } }
      );

      const bySpecies = Object.entries(observationsPerDataset).map(([setName, datasetData]) => {
        const observationsBySpecies = datasetData.observations.reduce(
          (bySpecies, observation) => {
            const existingEntry = bySpecies.options[observation.speciesId];
            const relevantInfo = {
              count: existingEntry ? existingEntry.count + 1 : 1,
              image: getImageForSpecies(
                observation.datasetId,
                observation.speciesId,
                state.datasets
              ),
              name: observation.speciesName,
            };
            return {
              ...bySpecies,
              options: {
                ...bySpecies.options,
                [observation.speciesId]: relevantInfo,
              },
            };
          },
          { name: datasetData.name, options: {} } as {
            name: string;
            options: { [speciesId: string]: { count: number; image: string; name: string } };
          }
        );
        return [setName, observationsBySpecies];
      });

      return fromPairs(bySpecies);
    },
  },
  mutations: {
    setUserId(state, userId: string) {
      state.userId = userId;
    },
    setLatestTally(state, latestTally: { datasetId: string; observationIds: string[] }) {
      state.latestTally = latestTally;
    },
    clearLatestTally(state) {
      state.latestTally = null;
    },
    setDatasets(state, datasets: IDataset[]) {
      state.datasets = datasets;
    },
    setDatasetsError(state, error: Error | null) {
      state.datasetsError = error;
    },
    setObservations(state, observations: IFirestoreObservation[]) {
      state.observations = observations;
    },
    setObservationsError(state, error: Error | null) {
      state.observationsError = error;
    },
    setGenericContent(state, genericContent: IGenericTurfappContent) {
      state.genericContent = genericContent;
    },
    addObservations(state, observations: IFirestoreObservation[]) {
      state.observations = state.observations.concat(observations);
      state.pendingObservations = [];
    },
    clearPendingObservations(state) {
      state.pendingObservations = [];
    },
    incrementObservation(state, observation: IFirestoreObservation) {
      state.pendingObservations.push(observation);
    },
    decrementObservation(state, speciesId: string) {
      const observation = state.pendingObservations.find(obs => obs.speciesId === speciesId);
      if (!observation) {
        return;
      }

      const index = state.pendingObservations.indexOf(observation);
      state.pendingObservations.splice(index, 1);
    },
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
  },
  actions: {
    async logIn({ commit }): Promise<void> {
      try {
        commit('setLoading', true);
        const userObj = await firebaseService.getAuth().signInAnonymously();
        if (!userObj || !userObj.user) {
          throw new Error('User object not found');
        }
        commit('setUserId', userObj.user.uid);
        commit('setLoading', false);
      } catch (error) {
        // tslint:disable-next-line
        console.error(error);
        commit('setLoading', false);
      }
    },
    async fetchDatasets({ commit }): Promise<void> {
      try {
        commit('setLoading', true);
        const datasets = await firebaseService.getDatasets();
        commit('setDatasets', datasets);
        commit('setLoading', false);
      } catch (error) {
        // I'm not logging the error because we're handling it in the UI
        commit('setDatasetsError', error);
        commit('setLoading', false);
      }
    },
    async fetchObservations({ state, commit }): Promise<void> {
      try {
        if (!state.userId) {
          throw new Error('Unauthorized.');
        }

        const observations = await firebaseService.getObservations(state.userId);
        commit('setObservations', observations);
        commit('setLoading', false);
      } catch (error) {
        // I'm not logging the error because we're handling it in the UI
        commit('setObservationsError', error);
        commit('setLoading', false);
      }
    },
    async addObservations({ state, commit }, coords?): Promise<void> {
      try {
        if (!state.userId) {
          throw new Error('Unauthorized.');
        }

        commit('setLoading', true);
        const observationsWithLocation = state.pendingObservations.map(obs => ({
          ...obs,
          lat: coords ? coords.lat : obs.lat,
          lon: coords ? coords.lon : obs.lon,
        }));

        const observations = await firebaseService.addObservations(
          state.userId,
          observationsWithLocation
        );
        await Api.addObservations(observations);

        commit('addObservations', observations);
        commit('setLoading', false);
      } catch (error) {
        // tslint:disable-next-line
        console.error(error);
      }
    },
    async fetchGenericContent({ commit }): Promise<void> {
      try {
        commit('setLoading', true);

        const genericContent = await ContentApi.fetchGenericTurfappContent();
        if (!genericContent) return;

        commit('setGenericContent', genericContent);
        commit('setLoading', false);
      } catch (error) {
        console.error(error);
        commit('setLoading', false);
      }
    },
  },
};

export default new Vuex.Store(store);

function getImageForSpecies(
  datasetId: string,
  speciesDocumentId: string,
  allDatasets: IDataset[]
): string {
  const dataset = allDatasets.find(d => d.id === datasetId);
  if (!dataset || !dataset.toolOptions) {
    return '';
  }

  const tool = dataset.toolOptions.find(o => o.speciesId === speciesDocumentId);
  if (!tool) {
    return '';
  }

  return tool.image;
}
