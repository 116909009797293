
import { Component, Prop, Vue } from 'vue-property-decorator';
import _ from 'lodash';

@Component
export default class ObservationListItem extends Vue {
  @Prop() public speciesName!: string;
  @Prop() public count!: number;
  @Prop() public image!: string;

  public get formattedName(): string {
    return _.capitalize(this.speciesName.replace(/_/g, ' ').trim());
  }
}
